<template>
  <div>
    <el-dialog :title="title" 
               width="25%"
               :modal-append-to-body="false"
               :visible="dialogTableVisible"
               @close="dialogClose">
      <div class="bef0 bbnone">
        <div class="h45 bbef0 flex-a-b-c pl10 pr10" v-for="(item,index) in memberData" :key="index"  @click="checkTap(item.cardId)"> 
          <span class="diy-radio" :class="chooseCardId == item.cardId ? 'check' : ' '" >{{''}}</span>
          <img :src="item.logo" class="w30 h30 br50p">
          <div class="w100 text-overflow">{{item.name}}</div>
          <p class="w100">{{item.phone}}</p>
        </div>
        
      </div>
      <div style="display: flex; justify-content: center; margin-top: 40px" v-if='memberData.length !== 0 '>
        <el-pagination
          background
          @current-change="currentChange"
          :current-page="dialogCurrentPage"
          layout="total, prev, pager, next, jumper"
          :total="total"
        />
      </div>
      <div class="mt40 flex-c-c">
        <el-button type="primary" plain @click="dialogClose">
          取消
        </el-button >
        <el-button type="primary" @click="onConfirmMember">
          确认
        </el-button >
      </div>
    </el-dialog>
    
  </div>
</template>

<script>
export default {
  name: "commonTable",
  props:{
    memberData:{
      type:Array,
      default:() => []
    },
    total:{ //总条数
      type :Number,
      default: 0
    },
    title:{ //弹出框样式
      type:String,
      default: ''
    },
    dialogCurrentPage:{ //当前页数
      type :Number,
      default: 1
    },
    dialogTableVisible:{ // 弹出框显隐
      type :Boolean,
      default: false
    },
    dialogCurrentChange:{
      type :Function,
      default: () => {}
    },
    
  },
  data() {
    return {
      chooseCardId:null,
    };
  },
  methods: {
    
     /**@method 监听下一页并调用父组件方法 */
    currentChange(val) {
      console.log(val)
      this.$emit('dialogCurrentChange',val);
      /* handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
      } */
    },
    //弹框页面切换
    dialogClose(val){
      this.$emit('dialogClose',val);
    },
    checkTap(id){
      this.chooseCardId = null
      this.chooseCardId = id
    },
    onConfirmMember(){
      let type = ''
      if(this.title == '选择售后'){
        type = 'afterSales'
      }
      
      this.$emit('turnOverMember',this.chooseCardId,type);
    }
  },
};
</script>

<style lang="scss" scoped>
</style>